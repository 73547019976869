/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// import { Modal } from 'react-responsive-modal'

import PageHeader from '../components/PageHeader'

// import PostSection from '../components/PostSection'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
const TermsPageTemplate = ({ data }) => {
  // const myRef = useRef(null)
  const { title, subTitle, featuredImage, termsAndConditions } =
    data.directus.bookingPage

  // useEffect(() => {
  //   if (open === true) {
  //     setTimeout(() => {
  //       const d = myRef.current
  //       d.scrollTop = 0
  //     }, 100)
  //   }
  // }, [open])

  return (
    <main className="Home">
      <PageHeader
        large
        title="Terms And Conditions"
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section">
        <div className="container">
          <Content body={termsAndConditions} />
        </div>
      </section>
    </main>
  )
}

// Export Default HomePage for front-end
const TermsPage = ({ data }) => {
  return (
    <Layout meta={data.directus.bookingPage.SEO || false}>
      <TermsPageTemplate data={data} />
    </Layout>
  )
}

export default TermsPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query TermsPage {
    directus {
      bookingPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        termsAndConditions
        SEO {
          image {
            id
          }
          title
          description
        }
      }
    }
  }
`
